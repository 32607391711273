import React from "react";

export const AboutPage = () => {
  return (
    <div>
      <section class="bg-white dark:bg-gray-900 ">
        <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
          <div class="flex justify-center xl:w-1/2">
            <img
              class="h-80 w-80 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-full"
              src="https://source.unsplash.com/4igCpD-Lnfg"
              alt=""
            />
          </div>

          <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
            <h2 class="text-3xl font-bold tracking-tight text-gray-800 xl:text-4xl dark:text-white">
              About Us
            </h2>

            <p class="block max-w-2xl mt-4 text-lg text-gray-500 dark:text-gray-300">
              The Infusion Process recycles effluent CO<sub>2</sub> to produce a
              low-Carbon-Intensity (CI) liquid fuel called
              Environmentally-Friendly Gasoline (EFG™). The CO<sub>2</sub> may
              be sourced- or captured-from the emissions and exhaust gasses
              generated by suppliers’ processes (natural gas processing, power
              generation, biomass combustion, other…). The recycled CO
              <sub>2</sub> (a problem by-product with negative economic and
              perception consequences) is combined with methane to produce a
              high-quality, drop-in gasoline. Infusion’s first project recycles
              100,000 tonnes of CO2 annually and produce over 160,000,000 litres
              of Environmentally-Friendly Gasoline.{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
