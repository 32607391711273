import React from 'react';
import { ContactForm } from '../components/ContactForm';

const Contact = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

export default Contact;
